<template>
	<div class="grid">
		<div class="col-12 lg:col-6 xl:col-3">
			<div class="card mb-0">
				<div class="flex justify-content-between mb-3">
					<div>
						<span class="block text-500 font-medium mb-3">Loans</span>
						<div class="text-900 font-medium text-xl">152</div>
					</div>
					<div class="flex align-items-center justify-content-center bg-blue-100 border-round" style="width:2.5rem;height:2.5rem">
						<i class="pi pi-shopping-cart text-blue-500 text-xl"></i>
					</div>
				</div>
				<span class="text-green-500 font-medium">24 new </span>
				<span class="text-500">since last visit</span>
			</div>
		</div>
		<div class="col-12 lg:col-6 xl:col-3">
			<div class="card mb-0">
				<div class="flex justify-content-between mb-3">
					<div>
						<span class="block text-500 font-medium mb-3">Savings</span>
						<div class="text-900 font-medium text-xl">$2.100</div>
					</div>
					<div class="flex align-items-center justify-content-center bg-orange-100 border-round" style="width:2.5rem;height:2.5rem">
						<i class="pi pi-map-marker text-orange-500 text-xl"></i>
					</div>
				</div>
				<span class="text-green-500 font-medium">%52+ </span>
				<span class="text-500">since last week</span>
			</div>
		</div>
		<div class="col-12 lg:col-6 xl:col-3">
			<div class="card mb-0">
				<div class="flex justify-content-between mb-3">
					<div>
						<span class="block text-500 font-medium mb-3">Customers</span>
						<div class="text-900 font-medium text-xl">28441</div>
					</div>
					<div class="flex align-items-center justify-content-center bg-cyan-100 border-round" style="width:2.5rem;height:2.5rem">
						<i class="pi pi-inbox text-cyan-500 text-xl"></i>
					</div>
				</div>
				<span class="text-green-500 font-medium">520  </span>
				<span class="text-500">newly registered</span>
			</div>
		</div>
		<div class="col-12 lg:col-6 xl:col-3">
			<div class="card mb-0">
				<div class="flex justify-content-between mb-3">
					<div>
						<span class="block text-500 font-medium mb-3">Comments</span>
						<div class="text-900 font-medium text-xl">152 Unread</div>
					</div>
					<div class="flex align-items-center justify-content-center bg-purple-100 border-round" style="width:2.5rem;height:2.5rem">
						<i class="pi pi-comment text-purple-500 text-xl"></i>
					</div>
				</div>
				<span class="text-green-500 font-medium">85 </span>
				<span class="text-500">responded</span>
			</div>
		</div>

	<div class="col-12 xl:col-12">
		
		<div class="card">
			<div class="flex justify-content-between align-items-center mb-5">
				<h5>Best Selling Products</h5>
				<div>
					<Button icon="pi pi-ellipsis-v" class="p-button-text p-button-plain p-button-rounded" @click="$refs.menu2.toggle($event)"></Button>
					<Menu ref="menu2" :popup="true" :model="items"></Menu>
				</div>
			</div>
			<ul class="list-none p-0 m-0">
				<li class="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4">
					<div>
						<span class="text-900 font-medium mr-2 mb-1 md:mb-0">Space T-Shirt</span>
						<div class="mt-1 text-600">Clothing</div>
					</div>
					<div class="mt-2 md:mt-0 flex align-items-center">
						<div class="surface-300 border-round overflow-hidden w-10rem lg:w-6rem" style="height:8px">
							<div class="bg-orange-500 h-full" style="width:50%"></div>
						</div>
						<span class="text-orange-500 ml-3 font-medium">%50</span>
					</div>
				</li>
				<li class="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4">
					<div>
						<span class="text-900 font-medium mr-2 mb-1 md:mb-0">Portal Sticker</span>
						<div class="mt-1 text-600">Accessories</div>
					</div>
					<div class="mt-2 md:mt-0 ml-0 md:ml-8 flex align-items-center">
						<div class="surface-300 border-round overflow-hidden w-10rem lg:w-6rem" style="height:8px">
							<div class="bg-cyan-500 h-full" style="width:16%"></div>
						</div>
						<span class="text-cyan-500 ml-3 font-medium">%16</span>
					</div>
				</li>
				<li class="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4">
					<div>
						<span class="text-900 font-medium mr-2 mb-1 md:mb-0">Supernova Sticker</span>
						<div class="mt-1 text-600">Accessories</div>
					</div>
					<div class="mt-2 md:mt-0 ml-0 md:ml-8 flex align-items-center">
						<div class="surface-300 border-round overflow-hidden w-10rem lg:w-6rem" style="height:8px">
							<div class="bg-pink-500 h-full" style="width:67%"></div>
						</div>
						<span class="text-pink-500 ml-3 font-medium">%67</span>
					</div>
				</li>
				<li class="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4">
					<div>
						<span class="text-900 font-medium mr-2 mb-1 md:mb-0">Wonders Notebook</span>
						<div class="mt-1 text-600">Office</div>
					</div>
					<div class="mt-2 md:mt-0 ml-0 md:ml-8 flex align-items-center">
						<div class="surface-300 border-round overflow-hidden w-10rem lg:w-6rem" style="height:8px">
							<div class="bg-green-500 h-full" style="width:99%"></div>
						</div>
						<span class="text-green-500 ml-3 font-medium">99%</span>
					</div>
				</li>
				<li class="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4">
					<div>
						<span class="text-900 font-medium mr-2 mb-1 md:mb-0">Mat Black Case</span>
						<div class="mt-1 text-600">Accessories</div>
					</div>
					<div class="mt-2 md:mt-0 ml-0 md:ml-8 flex align-items-center">
						<div class="surface-300 border-round overflow-hidden w-10rem lg:w-6rem" style="height:8px">
							<div class="bg-purple-500 h-full" style="width:75%"></div>
						</div>
						<span class="text-purple-500 ml-3 font-medium">%75</span>
					</div>
				</li>
				<li class="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4">
					<div>
						<span class="text-900 font-medium mr-2 mb-1 md:mb-0">Robots T-Shirt</span>
						<div class="mt-1 text-600">Clothing</div>
					</div>
					<div class="mt-2 md:mt-0 ml-0 md:ml-8 flex align-items-center">
						<div class="surface-300 border-round overflow-hidden w-10rem lg:w-6rem" style="height:8px">
							<div class="bg-teal-500 h-full" style="width:40%"></div>
						</div>
						<span class="text-teal-500 ml-3 font-medium">%40</span>
					</div>
				</li>
			</ul>
		</div>
	</div>
	<div class="col-12 xl:col-12">
		<div class="card">
			<h5>Deductions Overview</h5>
			<Chart type="line" :data="lineData" :options="lineOptions" />
		</div>
	
	</div>
	</div>
</template>

<script>
import EventBus from '@/AppEventBus';

// import axios from 'axios'
export default {
	data() {
		return {
		
		}
	},

	mounted() {
	




	
	},
	beforeUnmount() {
     
    },
	created() {
	},
	
	methods: {
	}
}
</script>